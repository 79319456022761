.cadex-wtk-viewer .viewer-tools {
  --viewer-tools-button-color: #5F6977;
  --viewer-tools-button-bg: var(--cadex-viewer-ui-kit-body-bg);
  --viewer-tools-button-border: 1px solid #e4e4e4;

  --viewer-tools-button-hover-bg: var(--cadex-viewer-ui-kit-body-bg);
  --viewer-tools-button-hover-color: #2d9cdb;
  --viewer-tools-button-hover-border: 1px solid var(--viewer-tools-button-hover-color);

  --viewer-tools-button-active-bg: var(--cadex-viewer-ui-kit-body-bg);
  --viewer-tools-button-active-color: #1f7fb7;
  --viewer-tools-button-active-border: 1px solid var(--viewer-tools-button-active-color);

  --viewer-tools-dropdown-menu-header-padding-x: 1rem;
  --viewer-tools-dropdown-menu-header-padding-y: 0.5rem;
  --viewer-tools-dropdown-menu-header-color: #6c757d;

  --viewer-tools-dropdown-menu-divider-background: var(--cadex-viewer-ui-kit-border-color-translucent);
  --viewer-tools-dropdown-menu-divider-margin-y: 0.5rem;

  --viewer-tools-dropdown-menu-item-color: var(--cadex-viewer-ui-kit-body-color);
  --viewer-tools-dropdown-menu-item-border: 1px solid transparent;
  --viewer-tools-dropdown-menu-item-padding-x: 1rem;
  --viewer-tools-dropdown-menu-item-padding-y: 0.5rem;
  --viewer-tools-dropdown-menu-item-border-radius: 0;

  --viewer-tools-dropdown-menu-item-hover-color: var(--cadex-viewer-ui-kit-body-color);
  --viewer-tools-dropdown-menu-item-hover-background: #00244807;

  --viewer-tools-dropdown-menu-item-active-color: var(--cadex-viewer-ui-kit-body-color);
  --viewer-tools-dropdown-menu-item-active-background: #0085d42a;

  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translate(-50%, 0);

  display: flex;
  gap: 0.5rem;
}

:root[data-theme="dark"] {
  .cadex-wtk-viewer .viewer-tools {
    --viewer-tools-button-color: #e4e4e4;
  }

  .cadex-wtk-viewer .viewer-tools__display-options-menu {
    --viewer-tools-dropdown-menu-header-color: #e4e4e4;
  }
}

.cadex-wtk-viewer .viewer-tools__button {
  padding: calc(0.75rem - 1px);
  color: var(--viewer-tools-button-color);
  border: var(--viewer-tools-button-border);
  background-color: var(--viewer-tools-button-bg);
}

.cadex-wtk-viewer .viewer-tools__button svg {
  display: block;
}

.cadex-wtk-viewer .viewer-tools__button:hover {
  color: var(--viewer-tools-button-hover-color);
  border: var(--viewer-tools-button-hover-border);
  background-color: var(--viewer-tools-button-hover-bg);
}

.cadex-wtk-viewer .viewer-tools__button:active,
.cadex-wtk-viewer .viewer-tools__button_active,
.cadex-wtk-viewer .viewer-tools__button.dropdown__toggle_active {
  color: var(--viewer-tools-button-active-color);
  border: var(--viewer-tools-button-active-border);
  background-color: var(--viewer-tools-button-active-bg);
}

.cadex-wtk-viewer .viewer-tools__display-options-menu {
  text-align: left;
  list-style: none;
}

.cadex-wtk-viewer .viewer-tools__display-options-menu,
.cadex-wtk-viewer .viewer-tools__explode-menu {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cadex-wtk-viewer .viewer-tools__display-options-menu-header,
.cadex-wtk-viewer .viewer-tools__explode-menu-header {
  display: block;
  padding: var(--viewer-tools-dropdown-menu-header-padding-y) var(--viewer-tools-dropdown-menu-header-padding-x);
  color: var(--viewer-tools-dropdown-menu-header-color);
  margin-bottom: 0;
  font-size: .875rem;
  white-space: nowrap;
}

.cadex-wtk-viewer .viewer-tools__display-options-menu-divider,
.cadex-wtk-viewer .viewer-tools__explode-menu-divider {
  height: 0;
  margin: var(--viewer-tools-dropdown-menu-divider-margin-y) 0;
  border: 0;
  border-top: 1px solid var(--viewer-tools-dropdown-menu-divider-background);
  overflow: hidden;
  color: inherit;
  opacity: 1;
}

.cadex-wtk-viewer .viewer-tools__display-options-menu-divider:last-child {
  display: none;
}

.cadex-wtk-viewer .viewer-tools__display-options-menu-item,
.cadex-wtk-viewer .viewer-tools__explode-menu-slider {
  flex: 1 1 auto;
  display: flex;
  width: 100%;
  padding: var(--viewer-tools-dropdown-menu-item-padding-y) var(--viewer-tools-dropdown-menu-item-padding-x);
  border: var(--viewer-tools-dropdown-menu-item-border);
  clear: both;
  color: var(--viewer-tools-dropdown-menu-item-color);
  font-size: var(--viewer-tools-dropdown-menu-font-size);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--viewer-tools-dropdown-menu-item-border-radius);
}

.cadex-wtk-viewer .viewer-tools__display-options-menu-item_active,
.cadex-wtk-viewer .viewer-tools__display-options-menu-item:active {
  color: var(--viewer-tools-dropdown-menu-item-active-color) !important;
  background-color: var(--viewer-tools-dropdown-menu-item-active-background) !important;
  text-decoration: none;
}

.cadex-wtk-viewer .viewer-tools__display-options-menu-item:hover {
  color: var(--viewer-tools-dropdown-menu-item-hover-color);
  background-color: var(--viewer-tools-dropdown-menu-item-hover-background);
}

.cadex-wtk-viewer .viewer-tools__display-options-menu-item>span {
  white-space: nowrap;
  margin-left: 0.5rem;
}

.cadex-wtk-viewer .viewer-tools__explode-menu-slider {
  align-items: center;
}

.cadex-wtk-viewer .viewer-tools__explode-menu-slider>input[type="range"] {
  width: 10rem;
}

.cadex-wtk-viewer .viewer-tools__explode-menu-slider-value {
  min-width: 3.5rem;
  text-align: right;
}

.cadex-wtk-viewer .viewer-tools__explode-menu-reset-button {
  margin: var(--viewer-tools-dropdown-menu-item-padding-y) var(--viewer-tools-dropdown-menu-item-padding-x);
  font-size: 0.875rem;
  line-height: 1.5;
}
