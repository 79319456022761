.cadex-wtk-viewer .base-panel {
  --base-panel-padding-x: 0;
  --base-panel-padding-y: 0;
  --base-panel-font-size: 1rem;
  --base-panel-background: var(--cadex-viewer-ui-kit-body-bg);
  --base-panel-color: var(--cadex-viewer-ui-kit-body-color);
}

.cadex-wtk-viewer .base-panel {
  padding: var(--base-panel-padding-y) var(--base-panel-padding-x);
  margin: 0;
  font-size: var(--base-panel-font-size);
  color: var(--base-panel-color);
  background-color: var(--base-panel-background);
  background-clip: padding-box;

  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.cadex-wtk-viewer .base-panel.base-panel__menu_hidden {
  display: none !important;
}

.cadex-wtk-viewer .base-panel__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  margin-bottom: 0;
  border-bottom: var(--cadex-viewer-ui-kit-border-width) var(--cadex-viewer-ui-kit-border-style) var(--cadex-viewer-ui-kit-border-color);
}

.cadex-wtk-viewer .base-panel__title>* {
  margin-top: 0;
  margin-bottom: 0;
}

.cadex-wtk-viewer .base-panel__close-button {
  padding: 0.125rem;
  border: none;
  border-radius: 0;
  color: var(--cadex-viewer-ui-kit-body-color);
  background-color: inherit;
}

.cadex-wtk-viewer .base-panel__close-button:hover {
  color: var(--cadex-viewer-ui-kit-list-hover-color);
  background-color: var(--cadex-viewer-ui-kit-list-hover-bg);
}

.cadex-wtk-viewer .base-panel__close-button svg {
  display: block;
}

.cadex-wtk-viewer .base-panel__body {
  flex: 1 1 auto;
}
