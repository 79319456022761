/* ViewerNavbar */

.navbar {
  height: 4rem;
  max-height: 4rem;

  .hamburger, .brand {
    &:hover {
      cursor: pointer;
    }
  }

  .hamburger {
    position: relative;
  }

  .shareTitle {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
  }

  .popoverPanel {
    width: max-content;
  
    position: absolute;
    left: 0;
    top: calc(100% + 12px);
    z-index: 1;
  
    display: flex;
    flex-direction: column;

    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.07);

    .divider {
      width: 100%;
      border-top: 1px solid #E4E4E4;
    }

    .popoverLink {
      width: 100%;
  
      display: inline-flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
  
      padding: 12px;
  
      background-color: transparent;

      text-align: left;

      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    
      color: #333333;
      text-decoration: none;

      span {
        margin-left: 12px;
      }

      &:hover {
        color: #335EEA;
      }
      &:active {
        color: hsl(226, 81%, 46%);
      }
    }

    .import {
      &:active {
        background-color: transparent !important;
        color: hsl(226, 81%, 46%) !important;
      }
    }
  
  }

}
