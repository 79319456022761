/* Copy from jstree style, but with some fixes */
/* jsTree default theme */
.jstree-node,
.jstree-children,
.jstree-container-ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
}
.jstree-node {
  white-space: nowrap;
}
.jstree-anchor {
  display: inline-block;
  color: black;
  white-space: nowrap;
  padding: 0 4px 0 1px;
  margin: 0;
  vertical-align: top;
}
.jstree-anchor:focus {
  outline: 0;
}
.jstree-anchor,
.jstree-anchor:link,
.jstree-anchor:visited,
.jstree-anchor:hover,
.jstree-anchor:active {
  text-decoration: none;
  color: inherit;
}
.jstree-icon {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}
.jstree-icon:empty {
  display: inline-block;
  text-decoration: none;
  margin: 0;
  padding: 0;
  vertical-align: top;
  text-align: center;
}
.jstree-ocl {
  cursor: pointer;
}
.jstree-leaf > .jstree-ocl {
  cursor: default;
}
.jstree .jstree-open > .jstree-children {
  display: block;
}
.jstree .jstree-closed > .jstree-children,
.jstree .jstree-leaf > .jstree-children {
  display: none;
}
.jstree-anchor > .jstree-themeicon {
  margin-right: 2px;
}
.jstree-no-icons .jstree-themeicon,
.jstree-anchor > .jstree-themeicon-hidden {
  display: none;
}
.jstree-hidden,
.jstree-node.jstree-hidden {
  display: none;
}
.jstree-rtl .jstree-anchor {
  padding: 0 1px 0 4px;
}
.jstree-rtl .jstree-anchor > .jstree-themeicon {
  margin-left: 2px;
  margin-right: 0;
}
.jstree-rtl .jstree-node {
  margin-left: 0;
}
.jstree-rtl .jstree-container-ul > .jstree-node {
  margin-right: 0;
}
.jstree-wholerow-ul {
  position: relative;
  display: inline-block;
  min-width: 100%;
}
.jstree-wholerow-ul .jstree-leaf > .jstree-ocl {
  cursor: pointer;
}
.jstree-wholerow-ul .jstree-anchor,
.jstree-wholerow-ul .jstree-icon {
  position: relative;
}
.jstree-wholerow-ul .jstree-wholerow {
  width: 100%;
  cursor: pointer;
  position: absolute;
  left: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.jstree-contextmenu .jstree-anchor {
  -webkit-user-select: none;
  /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none;
  /* disable the IOS popup when long-press on a link */
}
.vakata-context {
  display: none;
}
.vakata-context,
.vakata-context ul {
  margin: 0;
  padding: 2px;
  position: absolute;
  background: #f5f5f5;
  border: 1px solid #979797;
  box-shadow: 2px 2px 2px #999999;
}
.vakata-context ul {
  list-style: none;
  left: 100%;
  margin-top: -2.7em;
  margin-left: -4px;
}
.vakata-context .vakata-context-right ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}
.vakata-context li {
  list-style: none;
}
.vakata-context li > a {
  display: block;
  padding: 0 2em 0 2em;
  text-decoration: none;
  width: auto;
  color: black;
  white-space: nowrap;
  line-height: 2.4em;
  text-shadow: 1px 1px 0 white;
  border-radius: 1px;
}
.vakata-context li > a:hover {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}
.vakata-context li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAIORI4JlrqN1oMSnmmZDQUAOw==");
  background-position: right center;
  background-repeat: no-repeat;
}
.vakata-context li > a:focus {
  outline: 0;
}
.vakata-context .vakata-context-hover > a {
  position: relative;
  background-color: #e8eff7;
  box-shadow: 0 0 2px #0a6aa1;
}
.vakata-context .vakata-context-separator > a,
.vakata-context .vakata-context-separator > a:hover {
  background: white;
  border: 0;
  border-top: 1px solid #e2e3e3;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  padding: 0;
  margin: 0 0 0 2.4em;
  border-left: 1px solid #e0e0e0;
  text-shadow: 0 0 0 transparent;
  box-shadow: 0 0 0 transparent;
  border-radius: 0;
}
.vakata-context .vakata-contextmenu-disabled a,
.vakata-context .vakata-contextmenu-disabled a:hover {
  color: silver;
  background-color: transparent;
  border: 0;
  box-shadow: 0 0 0;
}
.vakata-context li > a > i {
  text-decoration: none;
  display: inline-block;
  width: 2.4em;
  height: 2.4em;
  background: transparent;
  margin: 0 0 0 -2em;
  vertical-align: top;
  text-align: center;
  line-height: 2.4em;
}
.vakata-context li > a > i:empty {
  width: 2.4em;
  line-height: 2.4em;
}
.vakata-context li > a .vakata-contextmenu-sep {
  display: inline-block;
  width: 1px;
  height: 2.4em;
  background: white;
  margin: 0 0.5em 0 0;
  border-left: 1px solid #e2e3e3;
}
.vakata-context .vakata-contextmenu-shortcut {
  font-size: 0.8em;
  color: silver;
  opacity: 0.5;
  display: none;
}
.vakata-context-rtl ul {
  left: auto;
  right: 100%;
  margin-left: auto;
  margin-right: -4px;
}
.vakata-context-rtl li > a.vakata-context-parent {
  background-image: url("data:image/gif;base64,R0lGODlhCwAHAIAAACgoKP///yH5BAEAAAEALAAAAAALAAcAAAINjI+AC7rWHIsPtmoxLAA7");
  background-position: left center;
  background-repeat: no-repeat;
}
.vakata-context-rtl .vakata-context-separator > a {
  margin: 0 2.4em 0 0;
  border-left: 0;
  border-right: 1px solid #e2e3e3;
}
.vakata-context-rtl .vakata-context-left ul {
  right: auto;
  left: 100%;
  margin-left: -4px;
  margin-right: auto;
}
.vakata-context-rtl li > a > i {
  margin: 0 -2em 0 0;
}
.vakata-context-rtl li > a .vakata-contextmenu-sep {
  margin: 0 0 0 0.5em;
  border-left-color: white;
  background: #e2e3e3;
}
#jstree-marker {
  position: absolute;
  top: 0;
  left: 0;
  margin: -5px 0 0 0;
  padding: 0;
  border-right: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
.jstree-default .jstree-node,
.jstree-default .jstree-icon {
  background-repeat: no-repeat;
  background-color: transparent;
}
.jstree-default .jstree-anchor,
.jstree-default .jstree-animated,
.jstree-default .jstree-wholerow {
  transition: background-color 0.15s, box-shadow 0.15s;
}
.jstree-default .jstree-hovered {
  background: #e7f4f9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #cccccc;
}
.jstree-default .jstree-context {
  background: #e7f4f9;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #cccccc;
}
.jstree-default .jstree-clicked {
  background: #beebff;
  border-radius: 2px;
  box-shadow: inset 0 0 1px #999999;
}
.jstree-default .jstree-no-icons .jstree-anchor > .jstree-themeicon {
  display: none;
}
.jstree-default .jstree-disabled {
  background: transparent;
  color: #666666;
}
.jstree-default .jstree-disabled.jstree-hovered {
  background: transparent;
  box-shadow: none;
}
.jstree-default .jstree-disabled.jstree-clicked {
  background: #efefef;
}
.jstree-default .jstree-disabled > .jstree-icon {
  opacity: 0.8;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'jstree-grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#jstree-grayscale");
  /* Firefox 10+ */
  filter: gray;
  /* IE6-9 */
  -webkit-filter: grayscale(100%);
  /* Chrome 19+ & Safari 6+ */
}
.jstree-default .jstree-search {
  font-style: italic;
  color: #8b0000;
  font-weight: bold;
}
.jstree-default > .jstree-striped {
  min-width: 100%;
  display: inline-block;
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAkCAMAAAB/qqA+AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMNAMM9s3UAAAAXSURBVHjajcEBAQAAAIKg/H/aCQZ70AUBjAATb6YPDgAAAABJRU5ErkJggg==") left top repeat;
}
.jstree-default > .jstree-wholerow-ul .jstree-hovered,
.jstree-default > .jstree-wholerow-ul .jstree-clicked {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
}
.jstree-default .jstree-wholerow {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.jstree-default .jstree-wholerow-hovered {
  background: #e7f4f9;
}
.jstree-default .jstree-wholerow-clicked {
  background: #beebff;
  background: -webkit-linear-gradient(top, #beebff 0%, #a8e4ff 100%);
  background: linear-gradient(to bottom, #beebff 0%, #a8e4ff 100%);
}
.jstree-default .jstree-node {
  min-height: 24px;
  line-height: 24px;
  margin-left: 24px;
  min-width: 24px;
}
.jstree-default .jstree-anchor {
  line-height: 24px;
  height: 24px;
}
.jstree-default .jstree-icon {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.jstree-default .jstree-icon:empty {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.jstree-default .jstree-wholerow {
  height: 24px;
}
.jstree-default .jstree-node {
  background-position: -292px -4px;
  background-repeat: repeat-y;
}
.jstree-default .jstree-last {
  background: transparent;
}
.jstree-default .jstree-open > .jstree-ocl {
  background-position: -132px -4px;
}
.jstree-default .jstree-closed > .jstree-ocl {
  background-position: -100px -4px;
}
.jstree-default .jstree-leaf > .jstree-ocl {
  background-position: -68px -4px;
}
.jstree-default .jstree-themeicon {
  background-position: -260px -4px;
}
.jstree-default > .jstree-no-dots .jstree-node,
.jstree-default > .jstree-no-dots .jstree-leaf > .jstree-ocl {
  background: transparent;
}
.jstree-default > .jstree-no-dots .jstree-open > .jstree-ocl {
  background-position: -36px -4px;
}
.jstree-default > .jstree-no-dots .jstree-closed > .jstree-ocl {
  background-position: -4px -4px;
}
.jstree-default .jstree-disabled {
  background: transparent;
}
.jstree-default .jstree-disabled.jstree-hovered {
  background: transparent;
}
.jstree-default .jstree-disabled.jstree-clicked {
  background: #efefef;
}
.jstree-default > .jstree-striped {
  background-size: auto 48px;
}
.jstree-default .jstree-themeicon-custom {
  background-color: transparent;
  background-image: none;
  background-position: 0 0;
}
/*.jstree-default > .jstree-container-ul .jstree-loading > .jstree-ocl {
  background: url("throbber.gif") center center no-repeat;
}*/
.jstree-default > .jstree-container-ul > .jstree-node {
  margin-left: 0;
  margin-right: 0;
}

.jstree-default .jstree-anchor > .jstree-icon.jstree-sge-state {
  background-position: -164px -4px;
}

.jstree-default .jstree-anchor > .jstree-icon.jstree-sge-state:hover {
  background-position: -164px -36px;
}

.jstree-default .jstree-anchor.jstree-displayed > .jstree-icon.jstree-sge-state {
  background-position: -228px -4px;
}

.jstree-default .jstree-anchor.jstree-displayed > .jstree-icon.jstree-sge-state:hover {
  background-position: -228px -36px;
}

.jstree-default .jstree-anchor.jstree-partly-displayed > .jstree-icon.jstree-sge-state {
  background-position: -196px -4px;
}

.jstree-default .jstree-anchor.jstree-partly-displayed > .jstree-icon.jstree-sge-state:hover {
  background-position: -196px -36px
}

.jstree-default .jstree-anchor.jstree-sge-loading > .jstree-icon.jstree-sge-state {
  background-image: none !important;
  position: relative;
}

.jstree-default .jstree-anchor.jstree-sge-loading > .jstree-icon.jstree-sge-state::before {
  display: inline-block;
  position: absolute;
  top: 4px;
  right: 4px;
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #224d69; /* Blue */
  border-radius: 50%;
  width: 12px;
  height: 12px;
  content: "";
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.jstree-anchor > .jstree-icon.jstree-sge-state {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMkMwIDAuODk1NDMxIDAuODk1NDMxIDAgMiAwSDE0QzE1LjEwNDYgMCAxNiAwLjg5NTQzMSAxNiAyVjE0QzE2IDE1LjEwNDYgMTUuMTA0NiAxNiAxNCAxNkgyQzAuODk1NDMxIDE2IDAgMTUuMTA0NiAwIDE0VjJaIiBmaWxsPSJub25lIi8+CjxwYXRoIGQ9Ik0yIDFIMTRWLTFIMlYxWk0xNSAyVjE0SDE3VjJIMTVaTTE0IDE1SDJWMTdIMTRWMTVaTTEgMTRWMkgtMVYxNEgxWk0yIDE1QzEuNDQ3NzIgMTUgMSAxNC41NTIzIDEgMTRILTFDLTEgMTUuNjU2OSAwLjM0MzE0NiAxNyAyIDE3VjE1Wk0xNSAxNEMxNSAxNC41NTIzIDE0LjU1MjMgMTUgMTQgMTVWMTdDMTUuNjU2OSAxNyAxNyAxNS42NTY5IDE3IDE0SDE1Wk0xNCAxQzE0LjU1MjMgMSAxNSAxLjQ0NzcyIDE1IDJIMTdDMTcgMC4zNDMxNDYgMTUuNjU2OSAtMSAxNCAtMVYxWk0yIC0xQzAuMzQzMTQ2IC0xIC0xIDAuMzQzMTQ2IC0xIDJIMUMxIDEuNDQ3NzIgMS40NDc3MiAxIDIgMVYtMVoiIGZpbGw9IiM2RjZGNkYiLz4KPC9zdmc+Cg==") !important;
  background-position: center !important;
  background-size: unset !important;
}
.jstree-anchor:hover > .jstree-icon.jstree-sge-state {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMkMwIDAuODk1NDMxIDAuODk1NDMxIDAgMiAwSDE0QzE1LjEwNDYgMCAxNiAwLjg5NTQzMSAxNiAyVjE0QzE2IDE1LjEwNDYgMTUuMTA0NiAxNiAxNCAxNkgyQzAuODk1NDMxIDE2IDAgMTUuMTA0NiAwIDE0VjJaIiBmaWxsPSJub25lIi8+CjxwYXRoIGQ9Ik0yIDFIMTRWLTFIMlYxWk0xNSAyVjE0SDE3VjJIMTVaTTE0IDE1SDJWMTdIMTRWMTVaTTEgMTRWMkgtMVYxNEgxWk0yIDE1QzEuNDQ3NzIgMTUgMSAxNC41NTIzIDEgMTRILTFDLTEgMTUuNjU2OSAwLjM0MzE0NiAxNyAyIDE3VjE1Wk0xNSAxNEMxNSAxNC41NTIzIDE0LjU1MjMgMTUgMTQgMTVWMTdDMTUuNjU2OSAxNyAxNyAxNS42NTY5IDE3IDE0SDE1Wk0xNCAxQzE0LjU1MjMgMSAxNSAxLjQ0NzcyIDE1IDJIMTdDMTcgMC4zNDMxNDYgMTUuNjU2OSAtMSAxNCAtMVYxWk0yIC0xQzAuMzQzMTQ2IC0xIC0xIDAuMzQzMTQ2IC0xIDJIMUMxIDEuNDQ3NzIgMS40NDc3MiAxIDIgMVYtMVoiIGZpbGw9IiMyRDlDREIiLz4KPC9zdmc+Cg==") !important;
  background-position: center !important;
  background-size: unset !important;
}

.jstree-anchor.jstree-displayed > .jstree-icon.jstree-sge-state {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMkMwIDAuODk1NDMxIDAuODk1NDMxIDAgMiAwSDE0QzE1LjEwNDYgMCAxNiAwLjg5NTQzMSAxNiAyVjE0QzE2IDE1LjEwNDYgMTUuMTA0NiAxNiAxNCAxNkgyQzAuODk1NDMxIDE2IDAgMTUuMTA0NiAwIDE0VjJaIiBmaWxsPSIjMkQ5Q0RCIi8+CjxwYXRoIGQ9Ik00IDguNUw3LjA1MDQ3IDExLjExNDdDNy4yODU1MyAxMS4zMTYyIDcuNjQ1MzMgMTEuMjU3OCA3LjgwNDYxIDEwLjk5MjNMMTIgNCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0yIDFIMTRWLTFIMlYxWk0xNSAyVjE0SDE3VjJIMTVaTTE0IDE1SDJWMTdIMTRWMTVaTTEgMTRWMkgtMVYxNEgxWk0yIDE1QzEuNDQ3NzIgMTUgMSAxNC41NTIzIDEgMTRILTFDLTEgMTUuNjU2OSAwLjM0MzE0NiAxNyAyIDE3VjE1Wk0xNSAxNEMxNSAxNC41NTIzIDE0LjU1MjMgMTUgMTQgMTVWMTdDMTUuNjU2OSAxNyAxNyAxNS42NTY5IDE3IDE0SDE1Wk0xNCAxQzE0LjU1MjMgMSAxNSAxLjQ0NzcyIDE1IDJIMTdDMTcgMC4zNDMxNDYgMTUuNjU2OSAtMSAxNCAtMVYxWk0yIC0xQzAuMzQzMTQ2IC0xIC0xIDAuMzQzMTQ2IC0xIDJIMUMxIDEuNDQ3NzIgMS40NDc3MiAxIDIgMVYtMVoiIGZpbGw9IiMyRDlDREIiLz4KPC9zdmc+Cg==") !important;
  background-position: center !important;
  background-size: unset !important;
}

.jstree-anchor.jstree-partly-displayed > .jstree-icon.jstree-sge-state {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgcng9IjEuNSIgZmlsbD0ibm9uZSIvPgo8cmVjdCB4PSIwLjUiIHk9IjAuNSIgd2lkdGg9IjE1IiBoZWlnaHQ9IjE1IiByeD0iMS41IiBzdHJva2U9IiMyRDlDREIiLz4KPHJlY3QgeD0iMyIgeT0iMyIgd2lkdGg9IjEwIiBoZWlnaHQ9IjEwIiByeD0iMSIgZmlsbD0iIzJEOUNEQiIvPgo8L3N2Zz4K") !important;
  background-position: center !important;
  background-size: unset !important;
}
