/* FeaturesSection */

.features-section {
  background: #FCFCFC;

  h2 {
    letter-spacing: 0.08em;
  }

  .card {
    padding: 32px 36px 32px 24px;
  }
}
