$home-page-scrollbar-width: 4px;

body {
  overflow-y: scroll;
  /* scroll bar (scrollbar) */
  &::-webkit-scrollbar {
    width: $home-page-scrollbar-width; /* width for vertical scroll */
    height: 4px; /* hight for horizontal scroll */
    background-color: transparent;
  }
  /* scrollbar slider */
  &::-webkit-scrollbar-thumb {
    width: $home-page-scrollbar-width;
    height: 100px;
    background-color: #BEBEBE;
    border-radius: 3px;
  }
  /* Firefox scrollbar */
  scrollbar-color: #BEBEBE transparent;     /* «color of slider «color of scroll bar» */
  scrollbar-width: thin;  /* width */
}
