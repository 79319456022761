.cadex-wtk-viewer .structure-panel {
  position: absolute;
  top: 0;
  left: 0;

  width: 25%;
  height: 100%;
  overflow: hidden;
  border: var(--cadex-viewer-ui-kit-border-width) var(--cadex-viewer-ui-kit-border-style) var(--cadex-viewer-ui-kit-border-color);
}

.cadex-wtk-viewer .structure-panel__tree {
  overflow: auto;
}

.cadex-wtk-viewer .structure-panel__tree.jstree-container-ul {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
