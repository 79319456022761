.cadex-wtk-viewer .dropdown {
  position: relative;
}

.cadex-wtk-viewer .dropdown__toggle {
  white-space: nowrap;
}

.cadex-wtk-viewer .dropdown__menu {
  --dropdown-menu-padding-x: 0;
  --dropdown-menu-padding-y: 0.5rem;
  --dropdown-menu-padding-min-width: 10rem;
  --dropdown-menu-font-size: 1rem;
  --dropdown-menu-background: var(--cadex-viewer-ui-kit-body-bg);
  --dropdown-menu-color: var(--cadex-viewer-ui-kit-body-color);
  --dropdown-menu-border-width: var(--cadex-viewer-ui-kit-border-width);
  --dropdown-menu-border-color: var(--cadex-viewer-ui-kit-border-color);
  --dropdown-menu-border-radius: var(--cadex-viewer-ui-kit-border-radius);
}

.cadex-wtk-viewer .dropdown__menu {
  padding: var(--dropdown-menu-padding-y) var(--dropdown-menu-padding-x);
  margin: 0;
  min-width: var(--dropdown-menu-padding-min-width);
  font-size: var(--dropdown-menu-font-size);
  color: var(--dropdown-menu-color);
  background-color: var(--dropdown-menu-background);
  background-clip: padding-box;
  border: var(--dropdown-menu-border-width) solid var(--dropdown-menu-border-color);
  border-radius: var(--dropdown-menu-border-radius);
}

.cadex-wtk-viewer .dropdown__menu_hidden {
  display: none !important;
}
