.cadex-wtk-viewer .properties-panel {
  position: absolute;
  top: 0;
  right: 0;

  width: 25%;
  height: 100%;
  overflow: hidden;
  border: var(--cadex-viewer-ui-kit-border-width) var(--cadex-viewer-ui-kit-border-style) var(--cadex-viewer-ui-kit-border-color);
  /* border-right: none; */
}

.cadex-wtk-viewer .properties-panel {
  --properties-panel-group-padding: 0.75rem;

  --properties-panel-group-title-collapse-button-icon-width: 1rem;
  --properties-panel-group-title-collapse-button-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQgMTBMNy45MjkyOSA2LjA3MDcxQzcuOTY4MzQgNi4wMzE2NiA3Ljk2ODM0IDUuOTY4MzQgNy45MjkyOSA1LjkyOTI5TDQgMiIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");
  --properties-panel-group-title-collapse-button-active-icon: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIgNEw1LjkyOTI5IDcuOTI5MjlDNS45NjgzNCA3Ljk2ODM0IDYuMDMxNjYgNy45NjgzNCA2LjA3MDcxIDcuOTI5MjlMMTAgNCIgc3Ryb2tlPSIjMzMzMzMzIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==");


  --properties-panel-property-font-size: 0.875rem;
  --properties-panel-property-line-height: 1.2;
  --properties-panel-property-margin-y: 0.5rem;
}

.cadex-wtk-viewer .properties-panel__empty-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cadex-wtk-viewer .properties-panel__body {
  overflow-y: auto;
}

.cadex-wtk-viewer .properties-panel__units {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: var(--properties-panel-group-padding);
  border-bottom: var(--cadex-viewer-ui-kit-border-width) var(--cadex-viewer-ui-kit-border-style) var(--cadex-viewer-ui-kit-border-color);
  margin-bottom: 0 !important;
}

.cadex-wtk-viewer .properties-panel__units-selector {
  margin-top: 0;
}

.cadex-wtk-viewer .properties-panel__group:not(:last-child) {
  border-bottom: var(--cadex-viewer-ui-kit-border-width) var(--cadex-viewer-ui-kit-border-style) var(--cadex-viewer-ui-kit-border-color);
}

.cadex-wtk-viewer .properties-panel__group-title {
  margin: 0;
  white-space: nowrap;
}

.cadex-wtk-viewer .properties-panel__group-collapse-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--properties-panel-group-padding);
  border: 0;
  border-radius: 0;
  color: var(--cadex-viewer-ui-kit-body-color);
  background-color: var(--cadex-viewer-ui-kit-body-bg);
}

.cadex-wtk-viewer .properties-panel__group-collapse-button:hover {
  color: var(--cadex-viewer-ui-kit-list-hover-color);
  background-color: var(--cadex-viewer-ui-kit-list-hover-bg);
}

.cadex-wtk-viewer .properties-panel__group-collapse-button::after {
  flex-shrink: 0;
  width: var(--properties-panel-group-title-collapse-button-icon-width);
  height: var(--properties-panel-group-title-collapse-button-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--properties-panel-group-title-collapse-button-active-icon);
  background-repeat: no-repeat;
  background-size: var(--properties-panel-group-title-collapse-button-icon-width);
}

.cadex-wtk-viewer .properties-panel__group_collapsed .properties-panel__group-collapse-button::after {
  background-image: var(--properties-panel-group-title-collapse-button-icon);
}

.cadex-wtk-viewer .properties-panel__group-body {
  padding: var(--properties-panel-group-padding);
}

.cadex-wtk-viewer .properties-panel__group_collapsed .properties-panel__group-body {
  display: none;
}

.cadex-wtk-viewer .properties-panel__property {
  display: flex;
  font-size: var(--properties-panel-property-font-size);
  line-height: var(--properties-panel-property-line-height);
  margin: var(--properties-panel-property-margin-y) 0;
  overflow-x: auto;
  overflow-y: hidden;
}

.cadex-wtk-viewer .properties-panel__property:first-child {
  margin-top: 0;
}

.cadex-wtk-viewer .properties-panel__property:last-child {
  margin-bottom: 0;
}

.cadex-wtk-viewer .properties-panel__property-name {
  flex: 0 0 9rem;
  padding-right: 0.25rem;
  overflow-wrap: break-word;
}

.cadex-wtk-viewer .properties-panel__property-value {
  flex: 1 1 auto;
  overflow-wrap: break-word;
}

.cadex-wtk-viewer .properties-panel__property-color-box {
  display: inline-block;
  height: 1em;
  width: 3em;
  margin-left: 0.25em;
  vertical-align: top;
  border: var(--cadex-viewer-ui-kit-border-width) solid var(--cadex-viewer-ui-kit-border-color);
}
