/* ModalError */

.modal-error {
  max-width: 640px !important;

  @media (max-width: 700px) {
    max-width: 90% !important;
    margin: 0 auto !important;
  }

  .modal-error-cross {
    height: 24px;
    width: 24px;

    position: absolute;
    top: 16px;
    right: 16px;

    @media (max-width: 400px) {
      display: none;
    }
  }
  .modal-error-icon {
    height: 64px;
    width: 64px;

    @media (max-width: 400px) {
      height: 48px;
      width: 48px;
    }
  }
  .modal-error-title {
    font-size: 31px;
    line-height: 42px;
    color: #000000;

    @media (max-width: 400px) {
      font-size: 21px;
      line-height: 29px;
    }
  }
  .modal-error-body {
    line-height: 23px;
    white-space: pre-line;

    @media (max-width: 400px) {
      padding: 0 50px;
      font-size: 14px;
      line-height: 19px;
      white-space: pre-line;
    }
  }
}