/* SamplesSection */

$samples-section-card-height: 343px;
$samples-section-card-margin-bottom: 2rem;
$samples-section-carousel-height: calc(#{$samples-section-card-height} + #{$samples-section-card-margin-bottom});
$samples-section-card-margin-x: 20px;

.samples-section {
  /* Disable text selections for prettier carousel scrolling. */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.samples-section-title {
  letter-spacing: 0.08em;
}

.samples-section-carousel {
  display: block !important;

  height: $samples-section-carousel-height !important;

  white-space: nowrap !important;
  overflow-x: visible !important;
  overflow-y: visible;

  /* Disable img selections for prettier carousel scrolling. */
  img {
    pointer-events: none;
  }

  /* Hide scrollbar FF and IE: */
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
  /* Hide scrollbar Webkit (Chrome, etc.): */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

.samples-section-card {
  display: inline-flex !important;
  width: 230px !important;
  height: $samples-section-card-height !important;
  margin: 0 $samples-section-card-margin-x !important;
  margin-bottom: $samples-section-card-margin-bottom !important;
  transition: all 0.3s ease;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}
