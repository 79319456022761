.cadex-wtk-viewer .drawing-structure-panel,
.cadex-wtk-viewer .layers-panel,
.cadex-wtk-viewer .properties-pane {
  position: absolute;
  top: 0;
  left: 0;

  width: 25%;
  height: 100%;
  overflow: hidden;
  border: var(--cadex-viewer-ui-kit-border-width) var(--cadex-viewer-ui-kit-border-style) var(--cadex-viewer-ui-kit-border-color);
}

.drawing-structure-panel .base-panel__body {
  display: flex;
  flex-direction: column;
  align-content: stretch;
}

.drawing-structure-panel__sheet-selector {
  padding: 0.5rem 0.75rem;
  display: flex;
  align-items: baseline;
  gap: 1rem;
}

.drawing-structure-panel__sheet-selector>select {
  flex: 1 1 auto;
  margin: 0;
}

.drawing-structure-panel__tree {
  flex: 1 1 1px;
  overflow: auto;
}

.drawing-structure-panel__tree.jstree-container-ul {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.drawing-structure-panel__tree .jstree-ocl {
  margin-left: 0.25rem !important;
}

.cadex-wtk-viewer .layers-panel .jstree-wholerow.jstree-wholerow-clicked {
  background: transparent !important;
}

.cadex-wtk-viewer .layers-panel .jstree-ocl {
  width: 0.5rem;
}

.jstree-icon.jstree-layer-color-icon {
  padding: 4px !important;
}

.jstree-icon.jstree-layer-color-icon>.color-box {
  display: inline-block;
  width: 100%;
  height: 100%;
  border: var(--ui-kit-border-width) solid var(--ui-kit-border-color);
}
